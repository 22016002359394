<template>
  <b-menu>
    <b-menu-list>
      <router-link
        v-if="creditEnabled"
        to="/user/applications"
        class="menu-item"
        @click.native="onClick"
      >
        <b-icon
          class="is-inline-block mr-4 menu-icon"
          icon-pack="fa"
          icon="wallet"
          size="is-small"
        ></b-icon>
        <span>{{ $t("af:sidebar.applications") }}</span>
      </router-link>

      <router-link
        to="/user/mortgage-applications"
        class="menu-item"
        @click.native="onClick"
      >
        <b-icon
          class="is-inline-block mr-4 menu-icon"
          icon-pack="fa"
          icon="home"
          size="is-small"
        ></b-icon>
        <span>{{ $t("af:sidebar.mortgage_applications") }}</span>
      </router-link>

      <router-link to="/user/todos" class="menu-item" @click.native="onClick">
        <b-icon
          class="is-inline-block mr-4 menu-icon"
          icon-pack="fa"
          icon="clipboard-list"
          size="is-small"
        ></b-icon>
        <span
          >{{ $t("af:sidebar.my_todos") }}
          <span class="todo-badge" v-if="todoCount > 0">
            {{ todoCount }}
          </span>
        </span>
      </router-link>

      <router-link
        v-if="creditEnabled && !isBrand(CUSTOM_APPEARANCE.MBH)"
        to="/user/purchase-history"
        class="menu-item"
        @click.native="onClick"
      >
        <b-icon
          class="is-inline-block mr-4 menu-icon"
          icon-pack="fa"
          icon="book"
          size="is-small"
        ></b-icon>
        <span>{{ $t("af:sidebar.purchase_history") }}</span>
      </router-link>

      <router-link to="/user/profile" class="menu-item" @click.native="onClick">
        <b-icon
          class="is-inline-block mr-4 menu-icon"
          icon-pack="fa"
          icon="user-cog"
          size="is-small"
        ></b-icon>
        <span>{{ $t("af:sidebar.profile") }}</span>
      </router-link>

      <router-link
        v-if="creditEnabled"
        to="/prepayments"
        class="menu-item"
        @click.native="onClick"
      >
        <b-icon
          class="is-inline-block mr-4 menu-icon"
          icon-pack="fa"
          icon="comment-dollar"
          size="is-small"
        ></b-icon>
        <span>{{ $t("af:sidebar.prepayments") }}</span>
      </router-link>

      <router-link
        v-if="creditEnabled && !isBrand(CUSTOM_APPEARANCE.MBH)"
        to="/stores"
        class="menu-item"
        @click.native="onClick"
      >
        <b-icon
          class="is-inline-block mr-4 menu-icon"
          icon-pack="fa"
          icon="store"
          size="is-small"
        ></b-icon>
        <span>{{ $t("af:sidebar.stores") }}</span>
      </router-link>

      <router-link :to="backPath" class="menu-item" @click.native="onClick">
        <b-icon
          class="is-inline-block mr-4 menu-icon"
          icon-pack="fa"
          icon="backspace"
          size="is-small"
        ></b-icon>
        <span>{{ $t("af:sidebar.back") }}</span>
      </router-link>
    </b-menu-list>
  </b-menu>
</template>

<script>
import { mapGetters } from "vuex";
import { APPLICATION_MODE, CUSTOM_APPEARANCE } from "@/utils/const";
import { creditEnabled, isBrand } from "@/utils/util";

export default {
  name: "MenuContent",
  computed: {
    CUSTOM_APPEARANCE: () => CUSTOM_APPEARANCE,
    ...mapGetters({
      todoCountLoan: "myTodo/getCount",
      todoCountMortgage: "myTodo/getMortgageCount",
    }),
    creditEnabled: () => creditEnabled(),
    backPath() {
      return this.$store.getters["profile/getCurrentApplicationMode"] ===
        APPLICATION_MODE.MORTGAGE
        ? this.$store.getters["profile/getMortgageBackPath"]
        : this.$store.getters["profile/getBackPath"];
    },
    todoCount() {
      return this.todoCountLoan + this.todoCountMortgage;
    },
  },
  methods: {
    isBrand,
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.todo-badge {
  margin-left: 2rem;
  background-color: $red;
  padding: 0.2rem 0.7rem;
  border-radius: 6px;
  color: white;
  font-size: 14px;
}
</style>
